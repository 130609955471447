<template>
  <div class="personhistDetails">
    <div v-if="loading || !personhist">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-if="personhist" v-bind:title="personhist.fullName" :pretitle="'Person hist details'" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button v-if="$store.getters.checkRole('legam.bib.personhist.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.bib.personhist.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button v-if="!$store.getters.checkRole('legam.bib.personhist.modify') && $store.getters.checkRole('legam.bib.personhist.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Full Name</h6>
                  <b-form-input
                    id="input-full-name"
                    v-model="personhist.fullName"
                    placeholder="Full Name"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="personhist.fullName"></span>
                </b-col>
                <b-col>
                  <h6>Occupation</h6>
                  <b-form-input
                    id="input-occupation"
                    v-model="personhist.occupation"
                    placeholder="Occupation"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="personhist.occupation"></span>
                </b-col>
                <b-col>
                  <h6>Comment</h6>
                  <b-form-input
                    id="input-comment"
                    v-model="personhist.comment"
                    placeholder="Comment"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="personhist.comment"></span>
                </b-col>
              </b-row>
            </article>
            <div class="mt-4" v-if="config.editable">
              <h6>Logs</h6>
              <ChangeLogsTable :objectType="'personhist'" :objectId="personhistId" :key="'logs' + index" />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import config from '@/config.js'

  export default {
    name: 'PersonhistDetails',
    data() {
      return {
        personhistId: this.$route.params.id,
        loading: true,
        editMode: false,
        index: 0,
        indexSigleSource: 0,
        modalSigleSourceRel: null,
        indexLink: 0,
        modalLink: null,
        config: config,
      }
    },
    components: {
      PageTitle,
      ChangeLogsTable,
    },
    computed: {
      ...mapState({
        personhist: state => state.personhist.personhist
      })
    },
    mounted() {
      this.$store.dispatch('loadPersonhist', this.personhistId)
    },
    watch: {
      'id'() {
        this.loading = true
        this.$store.dispatch('loadPersonhist', this.personhistId)
      },
      'personhist'() {
        this.loading = false
      }
    },
    methods: {
    //   addManuscript(manuscript){
    //     this.$bvModal.hide('modal-add-manuscript')
    //     this.$store.dispatch('addNotification', {
    //       type: 'success',
    //       text: `Manuscript added`
    //     })
    //     this.personhist.manuscripts.push(manuscript)
    //   },
    //   deleteManuscript(manuscriptId){
    //     this.personhist.manuscripts = this.personhist.manuscripts.filter(
    //       manuscript => manuscript.id != manuscriptId
    //     )
    //   },

      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadPersonhist', this.personhistId)
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('savePersonhist', this.personhist).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.index++
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deletePersonhist', this.personhist).then(() => {
              router.push('/personhists')
            })
          }
        })
      }
    }
  }
</script>
